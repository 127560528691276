.Navigation {
    background-color: #343a40 !important;
    overflow: hidden;
    align-items: center;

    a,
    button {
        max-width: none !important;
        padding: 12px !important;

        span>span {
            color: white !important;
        }
    }

    a[class*=" active"] {
        background-color: rgba(0, 0, 0, .2);
    }

    button {
        &:focus {
            background-color: transparent !important;
        }

        span>span:first-child>span {
            position: relative;
            top: 1px;
        }

        svg {
            position: relative;
            top: 2px;
            font-size: 1em !important;
        }
    }

    .middle {
        flex-grow: 1;
    }

    .middleLeft {
        @media only screen and (max-width: 420px) {
            flex-grow: 0;
        }
    }

    .navItems {
        text-align: right;

        a,
        button {
            padding: 18px 12px !important;
            transition: .3s ease;

            span {
                font-size: 1em !important;
            }

            &:hover {
                background-color: rgba(0, 0, 0, .1);
            }
        }
    }

    .MenuTrigger {
        margin-top: 15px;
        font-size: 1.8em;
        color: #FFFFFF;
        cursor: pointer;
    }
}

.LogoWrapper {
    display: flex;
    a {
        max-width: 180px !important;
    }
}

.UserTrigger {

    span {
        display: flex;
        align-items: center;
    }

    .UserLabel {
        display: inline-block;
        max-width: 200px;
        text-overflow: ellipsis;
        overflow-x: hidden;
        text-transform: capitalize !important;
        padding-bottom: 2px;
    }

    &:active,
    &:focus {
        background-color: transparent !important;
    }

    &:hover {
        background-color: rgba(0, 0, 0, .1);
    }

    @media only screen and (max-width: 959px) {
        width: 100%;
        max-width: inherit !important;

        &:hover {
            background-color: rgba(0, 0, 0, .2) !important;
        }

        svg {
            position: relative;
            top: 6px;
            width: .7em !important;
        }
    }
}

.DropdownMenu {
    div {
        min-width: 80px;

        ul {
            padding-top: 0 !important;
            padding-bottom: 0 !important;

            a {
                justify-content: center;
                padding-top: 10px;
                padding-bottom: 10px;
            }
        }
    }
}

.ExpansionTitle {
    div {
        &:first-child {
            justify-content: center;
        }
    }
}

.ExpansionDetails {
    padding: 0 !important;

    li {
        width: 100% !important;
        padding: 10px;
        justify-content: center;

        &:hover {
            background-color: rgba(0, 0, 0, .2);
        }
    }
}

.SideNav {
    a {
        display: block;
        padding: 10px;
        color: #000;
        text-align: center;
        text-decoration: none;

        &:hover {
            background-color: rgba(0, 0, 0, .2);
        }

        @media only screen and (max-width: 420px) {
            padding: 10px 15px;
        }
    }
}