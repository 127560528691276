.Logo {
    font-size: 2em !important;
    white-space: nowrap;
    svg {
        position: relative;
        top: 3px;
        margin-right: 5px;
    }
    @media only screen and (max-width: 420px) {
        font-size: 1.3em !important;
        position: relative;
        top: 9px;
        svg {
            display: none;
        }
    }
}
.Logo[class*=" drawer"] {
    top: 0;
    font-weight: 500;
    @media only screen and (max-width: 420px) {
        font-weight: bold;
    }
}