body {
  overflow-x: hidden;
  background-color: #f5f5f5; // #d1d3d6;
}

.page {
  min-height: calc(100vh - 118px);
  padding: 1em 0;
  font-size: 1.2em;
}

.MuiTypography-body1:not(.MuiTypography-alignCenter) {
  padding: 10px;
}

footer.footer {
  color: white;
  background-color: rgba(0,0,0,.8);
  padding: 20px 0;
  
  a {
    color: #FFFFFF;
  }
}