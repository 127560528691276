.Wrapper {
    margin: auto !important;
    margin-top: 20px !important;
}

.input {
    margin-bottom: 10px !important;
}

.Paper {
    padding: 15px !important;
    width: 100%;
}